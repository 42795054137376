<template>
    <Modal :contains-floating-button="true">
        <h1>{{ headline }}</h1>
        <Form @submit="edit ? update() : create()">
            <fieldset>
                <div class="radio-list" v-if="!edit">
                    <Radio label="Eget budget" v-model="from" value="own" :required="true"/>
                    <Radio label="Fra kontrakt" v-model="from" value="contract" :required="true"
                           @click="getContracts"/>
                </div>

                <template v-if="isFromContract">
                    <Select v-if="!edit" placeholder="Vælg kontrakt*" :required="isFromContract"
                            v-model="contract"
                            data-tooltip="Vælg kontrakt der acepteret og ikke allerede i brug.">
                        <option v-if="isLoadingContracts" disabled>Indlæser...</option>
                        <option v-else-if="!contracts.length" disabled>Ingen kontrakter oprettet</option>
                        <option v-else v-for="contract in contracts" :key="contract.id"
                                :value="contract.id" :disabled="contract.budget">
                            {{ contract.company.name }}
                            <template v-if="contract.budget">&nbsp;(I brug)</template>
                        </option>
                    </Select>

                    <template v-if="selectedContract">
                        <CardList>
                            <ContractCard v-bind="selectedContract" :readonly="true"/>
                        </CardList>
                        <Price v-if="selectedContract.agreement.payment.type === 'hourly'"
                               placeholder="Afsæt beløb, inkl. moms*" :required="true"
                               v-model="fields.payment.price"
                               tooltip="Afsæt beløb for hvad der forventes at blive brugt"/>
                        <!-- TODO: Prisen bliver aldrig sat ved redigering af budgetpost -->

                    </template>

                </template>

                <template v-if="isOwn">
                    <Input type="text" placeholder="Emne*" :required="isOwn" v-model="fields.title"
                           tooltip="Beskriv det overordnede emne for delposten – f.eks. Nyt tag"/>

                    <Textarea placeholder="Beskrivelse (valgfri)"
                           v-model="fields.description"
                           tooltip="Beskriv kort delpostens indhold"/>
                    <Price placeholder="Afsat beløb, inkl. moms*" :required="isOwn"
                           v-model="fields.payment.price"
                           tooltip="Samlet pris for f.eks. nyt tag"/>

                    <Upload label="Vedhæft bilag" v-model="fields.file"
                            tooltip="Vedhæft for mere detaljeret info som f.eks. tilbud fra håndværker, tømmerhandel eller egne noter"/>
                </template>
            </fieldset>
            <fieldset>
                <legend>Gruppering af budgetpost</legend>
                <div class="radio-list">
                    <Radio label="Tilknyt" v-model="group" value="select" :required="true"/>
                    <Radio label="Opret" v-model="group" value="create" :required="true"/>
                    <Radio label="Ingen" v-model="group" value="none" :required="true"/>
                </div>
                <Select v-if="group === 'select'" :required="true" placeholder="Vælg gruppe" v-model="fields.group" data-tooltip="Tilknyt budgetposten til en gruppe eller opret en ny">
                    <option v-if="isLoading" disabled>Indlæser...</option>
                    <option v-else-if="!groups.length" disabled>Ingen grupper oprettet</option>
                    <option v-else v-for="group in groups" :key="group" :value="group">
                        {{ group }}
                    </option>
                </Select>
                <Input v-if="group === 'create'" :required="true" type="text" placeholder="Opret gruppe" v-model="newGroup" tooltip="Opret en ny gruppe – f.eks. Køkken el. Loft"/>
            </fieldset>

            <ActionButton icon="check" :float="true"/>
        </Form>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Upload from '@/components/inputs/Upload.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import Price from '@/components/inputs/Price.vue';
import Form from '@/components/Form.vue';
import Input from '@/components/inputs/Input.vue';
import { budgetsCollection, contractsCollection } from '@/utils/collections';
import Radio from '@/components/inputs/Radio.vue';
import Select from '@/components/inputs/Select.vue';
import CardList from '@/components/CardList.vue';
import methods from '@/utils/methods';
import { FieldValue, uploadFile } from '@/utils/firebase';
import useState from '@/store';
import ContractCard from '@/components/cards/ContractCard.vue';
import { useRoute } from 'vue-router';
import Textarea from '@/components/inputs/Textarea.vue';
import useBudgetsGroups from '@/utils/useBudgetsGroups';

let unsubscribe;

export default {
    name: 'Budget',
    components: {
        Modal,
        Upload,
        ActionButton,
        Price,
        Form,
        Input,
        Radio,
        Select,
        CardList,
        ContractCard,
        Textarea,
    },
    mixins: [methods],
    setup() {
        const { project } = useState();
        const route = useRoute();
        const { groups, isLoading } = useBudgetsGroups();

        const { id } = route.params;
        const edit = !!id;
        const headline = edit ? 'Rediger budgetpost' : 'Tilføj budgetpost';

        return { project, edit, id, headline, groups, isLoading };
    },
    data() {
        return {
            fields: {
                title: null,
                description: null,
                payment: {
                    type: 'allocated',
                    price: null,
                    paid: 0,
                },
                group: '',
                file: null,
            },
            from: 'own',
            contract: '',
            contracts: [],
            isLoadingContracts: true,
            group: 'select',
            newGroup: '',
        };
    },
    computed: {
        isOwn() {
            return this.from === 'own';
        },
        isFromContract() {
            return this.from === 'contract';
        },
        selectedContract() {
            return this.contracts.filter((contract) => contract.id === this.contract)[0];
        },
    },
    mounted() {
        if (this.edit) this.setupBudgetData();
    },
    methods: {
        async getContracts() {
            if (!this.contracts.length) {
                unsubscribe = contractsCollection().onSnapshot((querySnapshot) => {
                    this.contracts = querySnapshot.docs.map((snapshot) => ({
                        id: snapshot.id,
                        ...snapshot.data(),
                    }));

                    this.isLoadingContracts = false;
                }, (err) => {
                    console.log(err);
                });
            }
        },
        async create() {
            try {
                this.fields.created = new Date();
                if (this.isFromContract) this.updateDataWithContract();

                const data = this.removeNullValues(this.fields);
                const { file } = data;

                if (this.group === 'create') data.group = this.newGroup;
                if (this.group === 'none') delete data.group;

                if (file) data.file = uploadFile(file, file.name, this.project.id);

                await budgetsCollection().add(data);
                await this.$router.push({ name: 'budget' });
            } catch (err) {
                console.log(err);
            }
        },
        async update() {
            try {
                const data = this.removeNullValues(this.fields);
                const { file } = data;

                if (this.group === 'create') data.group = this.newGroup;
                if (this.group === 'none') data.group = FieldValue.delete();

                data.file = file ? this.uploadOrUseLocalFile(file) : FieldValue.delete();

                await budgetsCollection().doc(this.id).update(data);
                await this.$router.push({ name: 'view-budget', params: { id: this.id } });
            } catch (err) {
                console.log(err);
            }
        },
        async setupBudgetData() {
            const budget = await budgetsCollection().doc(this.id).get();
            if (budget.exists) {
                const data = budget.data();
                this.fields.title = data.title;
                this.fields.description = data?.description || null;
                this.fields.payment = data.payment;
                this.fields.group = data.group ? data.group : '';
                this.fields.file = data?.file || null;
                this.from = data.contract ? 'contract' : 'own';

                if (data.contract) {
                    data.contract.ref.get().then((snapshot) => {
                        const contract = { id: snapshot.id, ...snapshot.data() };
                        this.contracts.push(contract);
                        this.contract = contract.id;
                    });
                }
            }
        },
        updateDataWithContract() {
            const contract = this.selectedContract;
            const { company, agreement, status } = contract;
            const { payment } = agreement;

            this.fields.contract = { ref: contractsCollection().doc(contract.id) };
            if (status) this.fields.contract.status = status.state;
            if (agreement.addition) this.fields.contract.addition = agreement.addition;
            this.fields.title = company.name;
            this.fields.description = agreement.description;
            this.fields.file = null;
            this.fields.payment.type = payment.type;
            if (payment.type !== 'hourly') this.fields.payment.price = payment.price;
        },
    },
    unmounted() {
        if (unsubscribe) unsubscribe();
    },
};
</script>

<style lang="less" scoped>
form fieldset {
    width: 100%;
}
</style>
