import { onMounted, onUnmounted, ref } from 'vue';
import { budgetsCollection } from '@/utils/collections';

export default function useBudgetsGroups() {
    let unsubscribe;
    const isLoading = ref(true);
    const groups = ref([]);

    onMounted(() => {
        unsubscribe = budgetsCollection().onSnapshot((querySnapshot) => {
            const docs = querySnapshot.docs.map((snapshot) => ({
                id: snapshot.id,
                ...snapshot.data(),
            })).map((doc) => doc.group).filter(Boolean);

            groups.value = [...new Set(docs)];

            isLoading.value = false;
        }, (err) => {
            console.log(err);
        });
    });

    onUnmounted(() => {
        unsubscribe();
    });

    return { groups, isLoading };
}
